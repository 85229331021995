<template>
  <div class="region">{{ text }}</div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          var list = JSON.parse(this.value);
          this.text = list.map((x) => x.name).join("-");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.album {
  display: flex;
  flex-wrap: wrap;
}
</style>
