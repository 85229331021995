<template>
  <div class="album">
    <el-image
      :preview-src-list="srcList"
      v-for="(url, index) in srcList"
      :initial-index="index"
      :key="url"
      :src="url"
      style="width: 60px; height: 60px;"
    ></el-image>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [],
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.srcList = JSON.parse(this.value);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.album{
    display: flex;
    flex-wrap: wrap;
}
</style>
