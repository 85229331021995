<template>
  <field-form-text
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'text'"
  ></field-form-text>
  <field-form-mobile
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'mobile'"
  ></field-form-mobile>
  <field-form-email
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'email'"
  ></field-form-email>
  <field-form-number
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'number'"
  ></field-form-number>
  <field-form-idcard
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'idcard'"
  ></field-form-idcard>
  <field-form-textarea
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'textarea'"
  ></field-form-textarea>
  <field-form-user
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'user'"
  ></field-form-user>
  <field-form-radio
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'radio'"
  ></field-form-radio>
  <field-form-checkbox
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'checkbox'"
  ></field-form-checkbox>
  <field-form-file
    :field="field"
    :batchId="batchId"
    :value="value"
    @change="onChange"
    v-if="field.type == 'file'"
  ></field-form-file>
  <field-form-date
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'date'"
  ></field-form-date>
  <field-form-datetime
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'datetime'"
  ></field-form-datetime>
  <field-form-region
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'region'"
  ></field-form-region>
  <field-form-select
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'select'"
  ></field-form-select>
  <field-form-image
    :field="field"
    :value="value"
    :batchId="batchId"
    @change="onChange"
    v-if="field.type == 'image'"
  ></field-form-image>
  <field-form-tag
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'cust_tag'"
  ></field-form-tag>
  <field-form-emp
    :field="field"
    :value="value"
    @change="onChange"
    v-if="field.type == 'emp'"
  ></field-form-emp>
</template>

<script>
export default {
  data() {
    return {
      //   value: "",
    };
  },
  props: {
    field: {
      type: Object,
      default: {},
    },
    /**
     * 批次id
     */
    batchId: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    // item: {
    //   type: Object,
    //   default: null,
    // },
  },
  emits: ["change"],
  //   watch: {
  //     value: {
  //       handler() {
  //         console.log(this.value);

  //       },
  //       immediate: true,
  //     },
  //   },
  methods: {
    /**
     * 更改
     */
    onChange(o) {
      this.$emit("change", o);
    },
  },
};
</script>

<style scoped></style>
