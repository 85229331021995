<!--
 * @Description: 多选
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-09 14:57:58
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <div>
      <el-checkbox-group v-model="checkList" @change="onChange">
        <el-checkbox
          :key="item"
          :label="item"
          :value="item"
          v-for="item in field.option_list"
        ></el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="help-block" v-if="field.input_tips">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
export default {
  data() {
    return {
      checkList: [],
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    /**
     *
     */
    value: {
      handler() {
        if (this.value) {
          this.checkList = JSON.parse(this.value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onChange() {
      this.$emit("change", JSON.stringify(this.checkList));
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
