<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-04-29 18:13:54
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-29 20:59:09
-->
<template>
  <el-sub-menu :index="father.meta.id" v-if="menus.length > 0">
    <template #title>
      <el-icon v-if="father.meta.icon != null && father.meta.icon != ''">
        <component :is="father.meta.icon" />
      </el-icon>
      {{ father.meta.title }}
    </template>

    <sub-menu v-for="item in father.children" :key="item" :father="item" :menus="item.children"></sub-menu>
  </el-sub-menu>

  <el-menu-item
    v-if="menus.length == 0"
    :index="father.meta.id"
    :route="{ path:father.path? `/${father.path}`:'' }"
  >
    <el-icon v-if="father.meta.icon != null && father.meta.icon != ''">
      <component :is="father.meta.icon" />
    </el-icon>
    {{ father.meta.title }}
  </el-menu-item>
</template>
<script>
export default {
  name: "SubMenu",
  data() {
    return {};
  },
  props: ["father", "menus"],
  watch: {
    menus: {
      handler() {
        // console.log("key", this.menus);
      },
    },
  },
  methods: {},
};
</script>