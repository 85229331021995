<!--
 * @Description: 客户标签
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-08 23:31:11
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <el-cascader
      :options="options"
      v-model="values"
      :props="props"
      @change="onChange"
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
    ></el-cascader>
    <div class="help-block">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
export default {
  data() {
    return {
      values: [],
      options: [],
      props: {
        label: "name",
        value: "id",
        multiple: true,
      },
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) {
          var list = JSON.parse(this.value);
          this.options.forEach((item) => {
            list.forEach((m) => {
              var x = item.children.find((o) => o.name == m.name);
              if (x) {
                this.values.push([item.id, x.id]);
              }
            });
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    var json = localStorage.getItem(this.$cfg.TAG_SELECT_STORE_KEY);
    if (json) {
      this.options = JSON.parse(json);
    }
  },
  methods: {
    /**
     * 加载数据
     */
    // loadData() {
    //   this.$http.get("admin/v1/crmLabel/select").then((res) => {
    //     if (res.code == 0 && res.data) {
    //       this.options = res.data.filter((x) => x.children.length > 0);
    //     }
    //   });
    // },
    /**
     * 项目选择更改
     */
    onChange() {
      var list = [];
      this.values.forEach((x) => {
        var id = x[x.length - 1];
        this.options.forEach((m) => {
          var model = m.children.find((p) => p.id == id);
          if (model) {
            list.push({
              name: model.name,
              color_hex: model.color_hex,
            });
          }
        });
      });
      this.$emit("change", JSON.stringify(list));
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
