<!--
 * @Description: 标签
 * @Author: 琢磨先生
 * @Date: 2024-05-08 16:52:01
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-09 09:23:34
-->
<template>
  <div>{{ text }}</div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          var list = JSON.parse(this.value);
          this.text = list.map((x) => x.name).join("、");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.album {
  display: flex;
  flex-wrap: wrap;
}
</style>
