<template>
  <el-container class="page skin">
    <el-aside width="200px">
      <side-bar :routes="routes"></side-bar>
    </el-aside>
    <el-container class="page-content">
      <el-header class="navbar">
        <el-container class="container">
          <div class="logo">
            <img alt="Vue logo" src="@/assets/logo.png" /> {{ setting.siteName }}
          </div>
          <ul class="nav">
            <!-- <li>
              <a href="#">111</a>
            </li>
             -->
            <li>
              <!-- <a href="#">2222</a> -->
              <el-link :underline="false" @click="clearCache">清理缓存</el-link>
            </li>
            <li class="nav-item">
              <div class="profile">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-avatar
                      :src="user.avatar_pic ? user.avatar_pic : avatar"
                    ></el-avatar>
                    <label>{{ user.name }}</label>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <router-link :to="{ path: '/profile' }">
                        <el-dropdown-item icon="el-icon-user"
                          >个人中心</el-dropdown-item
                        >
                      </router-link>
                      <el-dropdown-item
                        icon="el-icon-switch-button"
                        @click="onLogout"
                        >退出</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </li>
          </ul>
        </el-container>
      </el-header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-for="item in breadList" :key="item">{{
          item.meta.title
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <el-scrollbar> -->
      <el-main>
        <router-view></router-view>
      </el-main>
      <!-- </el-scrollbar> -->
    </el-container>
  </el-container>
</template>
<script>
import avatar from "@/assets/avatar.png";
import center_api from "../http/center_api";
import SideBar from "@/layout/sidebar";
import common_api from "@/http/common_api";
export default {
  name: "layout",
  components: {
    SideBar,
  },
  data() {
    return {
      avatar: avatar,
      user: {},
      routes: [],
      //breadcrumb列表
      breadList: [],
      is_can_flush_cache: false,
      setting:{},//公共配置参数
    };
  },
  created() {
    this.getBreadcrumb();

    common_api.get_setting().then((res) => {
      if (res.code == 0 && res.data) {
        this.setting = res.data;
      }
    });

    center_api.get_center().then((res) => {
      if (res.code == 0) {
        this.user = res.data;
        localStorage.setItem(this.$cfg.USER_STORE_KEY, JSON.stringify(res.data));
      }
    });
    /**
     * 获取我的菜单数据
     */
    center_api.get_menus().then((res) => {
      if (res.code == 0) {
        this.routes = res.data;
      }
    });

    /**
     *
     */
    center_api.get_apis().then((res) => {
      if (res.code == 0 && res.data) {
        var apis = res.data.map(m=>m.url);
        localStorage.setItem(this.$cfg.API_STORE_KEY, JSON.stringify(apis));
        this.is_can_flush_cache = this.$power("admin/v1/setting/flushCache");
      }
    });
  },
  watch: {
    /**
     * 监听路由变化
     */
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    /**
     * 退出登录
     */
    onLogout() {
      this.$http.get("admin/v1/center/out").then((res) => {
        if (res.code == 0) {
          localStorage.removeItem("token");
          this.$router.push({ name: "login" });
        }
      });
    },
    /**
     * 获取breadcrumb
     */
    getBreadcrumb() {
      if (
        this.$route.matched &&
        Object.keys(this.$route.matched[0].meta).length > 0
      ) {
        this.breadList = this.$route.matched;
        // var map = this.$route.matched.map((x) => {
        //   return {
        //     meta: x.meta,
        //     path: x.path,
        //   };
        // });
        // this.breadList = map;
        // var json = JSON.stringify(map);
        // localStorage.setItem("route_matched", json);
      } else {
        this.breadList = [];
        // localStorage.setItem('route_matched','');
      }
    },

    /**
     * 清空缓存
     */
    clearCache() {
      this.$confirm("您确定要清除系统所有缓存？", "温馨提示")
        .then(() => {
          this.$http.get("admin/v1/setting/flushCache").then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-main {
  display: flex;
  flex-direction: column;
}
/* 

.skin {
  height: 100%;
  --aside-width: 200px;
}

.skin .el-aside { 
  background-color: #304156;
  height: 100%;
}
.navbar {
  border-bottom: 1px solid var(--el-border-color);
  background: white;
}

.navbar_wrp {
  display: flex;
  align-items: center;
  height: 100%;
}
.navbar_wrp .logo {
  height: 60%;
  width: 180px;
}
.navbar_wrp .logo img {
  height: 100%;
  font-size: 0;
}
.navbar .menu {
  flex: 1;
}

.navbar .right {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar .right .item {
  height: 100%;
}
.navbar .right .item a {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 20px;
}
.navbar .right .user {
  display: flex;
  align-items: center;
}
.navbar .right .user .el-dropdown-link {
  display: flex;
  align-items: center;
}
.navbar .right .user .el-avatar {
  margin-right: 5px;
}

.navbar + .el-container { 
  overflow: auto;
} */
</style>
