const install = (app) => {
  /**
   * 动态表单初始化数据
   */
  app.config.globalProperties.$initFieldFormData = () => {
    // console.log(app.config.globalProperties.$cfg);
    var cfg = app.config.globalProperties.$cfg;
    var http = app.config.globalProperties.$http;
    /**
     * 加载用户列表，并存储 localStorage
     */
    http.get(`admin/v1/user/select`).then((res) => {
      if (res.code == 0 && res.data) {
        localStorage.setItem(
          cfg.USER_SELECT_STORE_KEY,
          JSON.stringify(res.data)
        );
      }
    });
    /**
     * 客户标签存储
     */
    http.get(`admin/v1/crmLabel/select`).then((res) => {
      if (res.code == 0 && res.data) {
        localStorage.setItem(
          cfg.TAG_SELECT_STORE_KEY,
          JSON.stringify(res.data)
        );
      }
    });
  };
};

export default { install };
