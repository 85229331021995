<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-04-29 18:13:54
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-29 20:49:24
-->
<template>
  <router-view></router-view>
</template>

<script>
export default {
  watch: {
    /**
     * 监听路由变化
     */
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    /**
     * 获取breadcrumb
     */
    getBreadcrumb() {
      if (
        this.$route.matched &&
        Object.keys(this.$route.matched[0].meta).length > 0
      ) {
        this.breadList = this.$route.matched;
      } else {
        this.breadList = [];
      }
    },
  },
};
</script>