<!--
 * @Description: 邮箱地址
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-07 16:59:02
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <el-input
      v-model="textValue"
      placeholder="请输入邮箱"
      type="email"
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
      @input="onChange"
    >
      <template #prefix>
        <el-icon><MessageBox /></el-icon>
      </template>
    </el-input>
    <div class="help-block">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
export default {
  data() {
    return {
      textValue: "",
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.textValue = this.value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onChange() {
      this.$emit("change", this.textValue);
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
