/*
 * @Description: md5 工具类
 * @Author: 琢磨先生
 * @Date: 2022-06-01 21:00:53
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-06 16:18:59
 */
import SparkMD5 from "spark-md5";

export default {
   /**
   * 获取文件的md5值
   * @param {*} file
   * @returns
   */
   fileMd5(file) {
    return new Promise((resolve, reject) => {
      var spark = new SparkMD5.ArrayBuffer();
      var fr = new FileReader();
      fr.onloadend = function (e) {
        spark.append(e.target.result);
        var md5 = spark.end();
        resolve(md5);
      };
      fr.onerror = function () {
        reject("获取文件md5值错误");
      };
      fr.readAsArrayBuffer(file);
    });
  },
};
