<!--
 * @Description: 省市县选择
 * @Author: 琢磨先生
 * @Date: 2024-05-06 09:30:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-05-08 21:23:38
-->
<template>
  <el-form-item
    :label="field.name"
    :class="`${field.is_required ? 'required' : ''}`"
  >
    <el-cascader
      :options="options"
      :props="props"
      clearable
      v-model="values"
      :style="field.style_percent ? `width:${field.style_percent}%` : ``"
      @change="onChange"
    ></el-cascader>
    <div class="help-block">{{ field.input_tips }}</div>
  </el-form-item>
</template>

<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      values: [],
      options: [],
      props: {
        checkStrictly: true,
        value: "code",
        label: "name",
      },
    };
  },
  props: {
    /**
     * 表单字段
     */
    field: {
      type: Object,
      default: {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  watch: {
    value: {
      handler() {
        if (this.value) {
          //   this.textValue = this.value;
          this.values = JSON.parse(this.value).map((x) => x.code);
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_region_tree().then((res) => {
      if (res.code == 0 && res.data) {
        this.options = res.data;
      }
    });
  },
  methods: {
    /**
     * 选择更改
     */
    onChange() {
      var result_list = []; //结果
      if (this.values.length) {
        //身份
        var province = this.options.find((o) => o.code == this.values[0]);
        result_list.push({
          code: province.code,
          name: province.name,
        });
        var city = {};
        if (this.values.length > 1) {
          city = province.children.find((o) => o.code == this.values[1]);
          result_list.push({
            code: city.code,
            name: city.name,
          });
        }
        if (this.values.length > 2) {
          var county = city.children.find((o) => o.code == this.values[2]);
          result_list.push({
            code: county.code,
            name: county.name,
          });
        }
      }
      console.log(result_list);
      this.$emit("change", result_list);
    },
  },
};
</script>

<style>
.el-form-item.required .el-form-item__label {
  position: relative;
}
.el-form-item.required .el-form-item__label::before {
  content: "*";
  position: absolute;
  right: 5px;
  display: block;
  color: red;
}
</style>
